import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, CircularProgress, Typography, styled } from '@material-ui/core';

import { leftArrow, CardDogImage } from '../assets';
import { defaultButton } from './FormWrapper';
import { Request, Shedule } from '../CustomisableUserProfilesController';
import RequestStatus from './RequestStatus';
import Field from './Field';
import { getLatestRequest } from '../../../../components/src/helpers';
import DetailsRequestFields from './DetailsRequestFields';
import DetailsApointmentFields from './DetailsApointmentFields';
import DisplayRequestReccurence from './DisplayRequestReccurence';
import DisplayAppointmentRecurrenceFields from './DisplayAppointmentRecurrenceFields';
import moment from 'moment';
import { transPt } from '../../../../components/src/HelperUtils';

type Props = {
    isLoading: boolean;
    selectedEvent: Shedule;
    onBackClick: (val: Shedule | null) => void; 
    toggleCancelation: () => void;
    toggleRequestChange: () => void;
    changeRequestStatus: (type: 'rejected' | 'accepted') => void;
}

const Wrapper = styled(Box)(({ theme }) => ({
    textAlign: 'left',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',

    "& .header-wrapper": {
        marginBottom: '24px',
        [theme.breakpoints.up("sm")]: {
            marginBottom: '40px',
          },
    },

    "& .header": {
        display: 'flex',
        gap: '16px',
        color: '#000',
        alignItems: 'center',
        [theme.breakpoints.up("sm")]: {
            gap: '24px',
          },
    },
    "& .header-text": {
        fontSize: '32px',
        fontWeight: 600,
        [theme.breakpoints.up("sm")]: {
            fontSize: '40px',
          },
    },
    "& .sections": {
        display: 'flex',
        flexDirection: 'column',
        gap: '32px'
    },
    "& .section-wrapper": {
        background: '#F6F6F6',
        padding: '32px 38px',
        borderRadius: '10px',
    },
    "& .fields-wrapper": {
        display: 'flex',
        gap: '32px',
        flexDirection: 'column',
        [theme.breakpoints.up("md")]: {
            flexDirection: 'row',
          },
    },
    "& .field-set": {
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        width: '100%'
    },
    "& .arrow-style": {
        userSelect: 'none', cursor: 'pointer'
    },
    "& .section-header": {
        display: 'flex', gap: '7px', alignItems: 'center', marginBottom: '32px'
    },
    "& .section-text": {
        color: '#6F05EA',
        fontSize: '18px',
        fontWeight: 600
    },
    "& .buttons-wrapper": {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        gap: '20px'
    },
    "& .secondary-btn": {
        ...defaultButton,
        border: "1px solid #4B4B4B",
        color: '#4B4B4B',
        padding: '10px 26px',
        fontWeight: 700,
        fontSize: '18px'
    },
    "& .main-btn": {
        ...defaultButton,
        border: "1px solid #6F05EA",
        color: '#6F05EA',
        fontWeight: 700,
        fontSize: '18px',
        padding: '10px 26px',
    },
    "& .accept-btn": {
        ...defaultButton,
        padding: '8px 39px',
        fontSize: '18px',
        fontWeight: 700,
        color: '#6F05EA',
        border: '1px solid #6F05EA',
        width: 'auto'
    },
    "& .reject-btn": {
        ...defaultButton,
        fontSize: '18px',
        color: '#C10000',
        fontWeight: 700,
        border: '1px solid #C10000',
        padding: '8px 39px',
        width: 'auto'
    },
    "& .req-buttons-wrapper": {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '19px',
        marginTop: '40px'
    }
}))

const getParams = (request: Request | null) => {
    if(!request) {
        return null;
    }
    return {
        isShowRequest: request && request.request_type === 'Reschedule Request' && request.created_by === 'vendor' && request.status === 'pending',
        isRequestCustomer: !!request && request.request_type === 'Reschedule Request' && request.created_by === 'customer',
        isCancelation: request && request.request_type === 'Cancellation Request' && request.status !== 'rejected',
        isEndDate: request && request.end_date,
        isTime: request && request.shift,
        isPending: request && request.status === 'pending'
    }
}

const getFeildsList = (serviceType: string, isReccurence: boolean) => {
    const fields = { startDate: true, endDate: false, time: false, reccurence: false, days: false, every: false, timeGrooming: false };

    if(serviceType === 'Hotels') {
        fields.endDate = true;
    }
    if(serviceType === 'Dog Walking') {
        fields.time = true;
        if(isReccurence) {
            fields.endDate = true;
            fields.reccurence = true;
            fields.days = true;
        }
    }
    if(serviceType === 'Grooming') {
        fields.timeGrooming = true;
        if(isReccurence) {
            fields.endDate = true;
            fields.days = true;
            fields.every = true;
        }
    }
    if(serviceType === 'Pet Sitting' && isReccurence) {
        fields.endDate = true;
        fields.days = true;
    }
    if(serviceType === 'Day Care' && isReccurence) {
        fields.endDate = true;
        fields.days = true;
        fields.reccurence = true;
    }

    return fields;
}

const getDisplayFields = (request: Request | null, serviceType: string, isReccurence: boolean) => {
    if(!request || request.request_type !== 'Reschedule Request' || request.created_by === 'customer' || request.status !== 'pending') {
        return null;
    }

    return getFeildsList(serviceType, isReccurence);
}

const ApointmentDetails = ({ isLoading, selectedEvent, onBackClick, toggleCancelation, changeRequestStatus, toggleRequestChange }: Props) => {
    const [actions, setActions] = useState(false);
    const { birthdate, breed, fur, gender, size, weight, pet_category, name } = selectedEvent.pet.data.attributes;
    const request = getLatestRequest(selectedEvent.request_details);
    const reqParams = getParams(request);
    const isReccurence = !!selectedEvent.repeat_weekly;
    const displayFields = getDisplayFields(request, selectedEvent.service.service_type, isReccurence);
    const fieldList = getFeildsList(selectedEvent.service.service_type, isReccurence);
    const isPast = moment(selectedEvent.end_date, 'YYYY-MM-DD').startOf('day').isBefore(moment().startOf('day'))

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const toggleActions = () => {
        setActions(!actions);
    }

    return (
        <Wrapper data-test-id="apointments-details">
            <Box className='header-wrapper'>
                <Box className='header'>
                    <Box data-test-id="back-btn" onClick={() => onBackClick(null)}><img src={leftArrow} className='arrow-style'/></Box>
                    <Typography className='header-text'>{name}</Typography>
                    <Typography className='header-text'>{selectedEvent.service.service_type}</Typography>
                </Box>
                <RequestStatus request={request} isRecurrence={isReccurence} />
            </Box>
            <Box className='sections'>
                {isReccurence && !isPast ? 
                    <DisplayRequestReccurence 
                        changeRequestStatus={changeRequestStatus} 
                        isLoading={isLoading}
                        request={request}
                        selectedEvent={selectedEvent}
                        displayFields={getFeildsList(selectedEvent.service.service_type, isReccurence)}
                    /> : 
                    <DetailsRequestFields 
                        changeRequestStatus={changeRequestStatus} 
                        displayFields={displayFields} 
                        isLoading={isLoading}
                        request={request}
                        selectedEvent={selectedEvent}
                    /> 
                }
                {isReccurence 
                    ? <DisplayAppointmentRecurrenceFields fieldList={fieldList} request={request} selectedEvent={selectedEvent}/> 
                    : <DetailsApointmentFields fieldList={fieldList} request={request} selectedEvent={selectedEvent}/>
                }
                <Box className='section-wrapper'>
                    <Box className='section-header'>
                        <Avatar src={CardDogImage} style={{ width: '24px', height: '24px'}} />
                        <Typography className='section-text'>{name}{transPt("`s Details", "customerUserProfile")}</Typography>
                    </Box>
                    <Box className='fields-wrapper'>
                        <Box className='field-set'>
                            <Field title='Animal' value={pet_category} />
                            {breed && <Field title={transPt("Breed", "customerUserProfile")} value={breed} />}
                            {weight && <Field title={transPt("Weight", "customerUserProfile")} value={weight} />}
                            {fur && <Field title={transPt("Fur", "customerUserProfile")} value={fur} />}
                        </Box>
                        <Box className='field-set'>
                            {gender && <Field title={transPt("Gender", "customerUserProfile")} value={gender} />}
                            <Field title={transPt("Birthdate", "customerUserProfile")} value={birthdate} />
                            {size && <Field title={transPt("Size", "customerUserProfile")} value={size} />}
                        </Box>
                    </Box>
                </Box>
                {!reqParams?.isPending && !isPast && !reqParams?.isCancelation &&
                    <Box className='buttons-wrapper'>
                        {actions ? 
                        <>
                            <Button data-test-id="req-change-btn" className='secondary-btn' onClick={toggleRequestChange}>{transPt("Request change", "customerUserProfile")}</Button>
                            <Button data-test-id="cancelation-btn" className='secondary-btn' onClick={toggleCancelation}>{transPt("Request cancellation", "customerUserProfile")}</Button>
                            <Button className='main-btn' onClick={toggleActions}>{transPt("Cancel", "customerUserProfile")}</Button>
                        </> 
                        : <Button data-test-id='action-btn' className='main-btn' onClick={toggleActions}>{transPt("Request change", "customerUserProfile")}</Button>
                        }
                    </Box>
                }
            </Box>
        </Wrapper>
    )
}


export default ApointmentDetails;