import React from "react";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import {
  appointmentCardTitleIcon,
  closeIcon,
  groomingIcon,
} from "./assets";
export const configJSON = require("./config");
import CustomCalendarDialog from "../../../components/src/calendarWeb/customDialog";
import { Formik } from "formik";
import InputField from "../../../components/src/InputField";
import { emailIcon } from "../../../blocks/email-account-registration/src/assets";
import * as Yup from "yup";
import VendorCalendar from "./components/VendorCalendar";
import VendorAppointmentList from "./components/VendorAppointmentsList";
import AvailabilityModal from "./components/VendorCalendarModals/AvailabilityModal";
import BookingDetailsModal from "./components/VendorCalendarModals/BookingDetailsModal";
import { ServiceList } from "./types";
// Customizable Area End

import CustomisableUserCalendarVendorController, {
  Props,
} from "./CustomisableUserCalendarVendorController";
import { FormError, FormErrorTouched } from "./generalInfo/Services.web";

// Customizable Area Start
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const addBookingSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("*Please enter client email to proceed.")
    .test("email validation", "Invalid email address", (value) => {
      if (!value) return false;
      return /^[a-zA-Z0-9._+~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value);
    })
    .trim(),
});

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ width: "100%" }}>{children}</Box>}
    </div>
  );
}
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
const CalendarContainer = styled(Box)(({ theme })=>({
  display:'contents',
  padding: "0px 186px",
  borderRadius:16,

  "@media (max-width: 960px)": {
    padding: "0px 96px",
  },
  '& *': {
    boxSizing: 'border-box',
    border: 'none',
  },

  "@media (max-width: 600px)": {
    padding: "0px 46px",
  },
  "@media (max-width: 400px)": {
    padding: "0px 16px",
  },
  "& .calendar-content": {
    width: "100%",
  },
  "& .add-booking-container": {
    display: "flex",
    justifyContent: "flex-end",
  },
  "& .add-booking-btn": {
    border: "1px solid #6F05EA",
    borderRadius: "100px",
    padding: "5px 45px",
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "none",
    color: "#6F05EA",
    backgroundColor: "#fff",
    boxShadow: "none",
    marginTop: "20px",
    marginBottom: "70px",
    width: '100%',
    "&:hover": {
      color: "#6F05EA",
      backgroundColor: "#fff",
      boxShadow: "none",
    },
    [theme.breakpoints.up("sm")]: {
      width: 'auto'
    },
    [theme.breakpoints.down("sm")]: {
      padding: "5px 35px",
      fontSize: "14px",
    },
  },
  "& .MuiTab-root": {
    textTransform: "none",
    fontWeight: 600,
    color: "#000000",
  },
  "& .MuiTab-textColorInherit.Mui-selected": {
    color: "#6F05EA",
    textTransform: "none",
    fontWeight: 600,
  },
  "& .navigation-container": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "30px",
  },
  "& .view-all-btn": {
    borderBottom: "1px solid #6F05EA",
    borderRadius: "0px",
    fontSize: "14px",
    fontWeight: 700,
    height: "fit-content",
    padding: "0px",
    textTransform: "none",
    color: "#6F05EA",
    backgroundColor: "transparent",
    boxShadow: "none",
    "&:hover": {
      color: "#6F05EA",
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  "& .card-container": {
    boxShadow: "0px 0px 3.5px 0px #00000040",
    borderRadius: "8px",
    marginBottom: "15px",
    backgroundColor: "#fff",
    cursor: 'pointer'
  },
  "& .card-title-container": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 19px",
    backgroundColor: "#6F05EA",
    borderRadius: "8px 8px 0px 0px",
  },
  "& .card-title": {
    color: "#fff",
    fontSize: "16px",
    fontWeight: "500",
    "@media (max-width: 960px)": {
      fontSize: "14px",
    },
    "@media (max-width: 600px)": {
      fontSize: "12px",
    },
  },
  "& .card-body-container": {
    padding: "26px 28px",
  },
  "& .card-body-info": {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "10px",
  },
  "& .card-title-img": {
    height: "31px",
    width: "40px",
  },
  "& .card-body-main-img": {
    height: "80px",
    width: "80px",
    minWidth: "80px",
    borderRadius: "50%",
    "@media (max-width: 960px)": {
      height: "75px",
      width: "75px",
    },
    "@media (max-width: 600px)": {
      height: "65px",
      width: "65px",
    },
  },
  "& .card-body-icon-img": {
    height: "20px",
    width: "20px",
  },
  "& .card-body-time": {
    fontSize: "16px",
    fontWeight: "600",
    color: "#B7B7B7",
    "@media (max-width: 960px)": {
      fontSize: "14px",
    },
    "@media (max-width: 600px)": {
      fontSize: "12px",
    },
  },
  "& .card-body-service-name": {
    fontSize: "16px",
    fontWeight: "600",
    color: "#B7B7B7",
    "@media (max-width: 960px)": {
      fontSize: "14px",
    },
    "@media (max-width: 600px)": {
      fontSize: "12px",
    },
  },
  "& .card-body-name": {
    fontSize: "20px",
    fontWeight: "600",
    "@media (max-width: 960px)": {
      fontSize: "18px",
    },
    "@media (max-width: 600px)": {
      fontSize: "16px",
    },
  },
  "& .past-appointment-container": {
    display: "flex",
    justifyContent: "space-between",
    margin: "40px 0px 25px 0px",
  },
  "& .past-appointment-title": {
    fontWeight: "600",
    fontSize: "20px",
  },
  "& .card-body-img-container": {
    display: "flex",
    justifyContent: "space-around",
  },
}));

const TabWrapper = styled(Tabs)({
  marginBottom: 50,
  "& .MuiTabs-root": {
    overflow: "auto"
  },
  
  "& .MuiTabs-scrollButtons": {
    display: "flex !important"
  }
})

// Customizable Area End

export default class CustomisableUserCalendarVendorWeb extends CustomisableUserCalendarVendorController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  
  renderAddBookingButton = () => {
    return (
      <Box className="add-booking-container">
        <Button
          className="add-booking-btn"
          onClick={this.handleAddBookingClick}
          data-test-id="add-booking-btn"
        >
          {this.transCalendar("Add Booking")}
        </Button>
      </Box>
    );
  };

  renderAppointmentCard = () => {
    return (
    <>
      {this.props.selectedService.service_id!=='' && this.state.RequestArrayList?.data.length>=1 && 
      this.state.RequestArrayList?.data.map(
        (item: any) => (
          <Grid container className="card-container" key={item.id}>
            <Grid item xs={12} className="card-title-container">
              <Box className="card-title">{new Date(item.attributes?.schedule_detail?.start_date).toLocaleDateString('en-GB', { weekday: 'short', day: 'numeric', month: 'long' })}</Box>
              <Box >
                <img className="card-title-img" src={appointmentCardTitleIcon} />
              </Box>
            </Grid>
            <Grid data-test-d='selectList' item xs={12} onClick={() => this.selectListItem(item)}>
              <Grid container className="card-body-container">
                <Grid
                  item
                  md={2}
                  sm={3}
                  xs={4}
                  className="card-body-img-container"
                >
                  <img
                    className="card-body-main-img"
                    src={item.attributes?.pet?.image_url}
                    alt="grooming-icon"
                  />
                  <img
                    className="card-body-icon-img"
                    src={groomingIcon }
                    alt="grooming-icon"
                  />
                </Grid>
                <Grid item md={10} sm={9} xs={8}>
                  <Box className="card-body-info">
                    <Box className="card-body-name">{item.attributes?.pet?.pet_detail?.name}</Box>
                    <Box className="card-body-service-name">
                      {item.attributes.service.service_type}
                    </Box>
                    {item.attributes.status=='pending' && <Box className="card-body-time" style={{color:'rgb(212, 173, 45)'}}>{item.attributes.request_type} is on approval</Box>}
                    {item.attributes.status=='accepted' && <Box className="card-body-time" style={{color:'rgb(5, 150, 105)'}}>{item.attributes.request_type} is approved</Box>}
                    {item.attributes.status=='rejected' && <Box className="card-body-time" style={{color:'rgb(193, 0, 0)'}}>{item.attributes.request_type} is rejected</Box>}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      )} </>
    )
  };

  renderRequestTabPanel = () => {
    return (
      <TabPanel
        value={this.state.tabValue}
        index={2}
        data-test-id="request-tab-data"
      >
        {this.renderAppointmentCard()}
      </TabPanel>
    );
  };

  

  renderAppointments = () => {
    return (
      <Grid container className="navigation-container">
        <Grid item style={{ overflow: 'hidden'}} sm={12}>
          <Tabs
            value={this.state.tabValue}
            onChange={this.handleTabChange}
            textColor="inherit"
            variant="scrollable"
            scrollButtons="on"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#6F05EA",
                height: "4px",
              },
            }}
            data-test-id="tabs"
          >
            <Tab label={this.transCalendar("Upcoming Appointments")} className="tab" />
            <Tab label={this.transCalendar("Past Appointments")} className="tab" />
            <Tab label={this.transCalendar("Requests")} className="tab" />
          </Tabs>
        </Grid>
      </Grid>
    );
  };

  getTitle = () => {
    if(!this.state.isShowSuccessfullyBookingEmail) {
      return (
        <Box className="dialog-title-container">
          <Typography className="dialog-title">
            {this.transCalendar("Please provide your client's email address")}
          </Typography>
        </Box>
      )
    } else {
      return (
        <Box className="dialog-successfully-title-container">
          <IconButton
            ata-test-id="add-booking-close-btn"
            onClick={this.handleAddBookingClose}
            className="dialog-close-btn"
          >
            <img className="dialog-close-icon" src={closeIcon} />
          </IconButton>
        </Box>
      )
    }
  }

  renderAddBookingModel = () => {
    return (
      <CustomCalendarDialog
        data-test-id="add-booking-dialog"
        open={this.state.isAddBookingOpen}
        onClose={this.handleAddBookingClose}
        title={this.getTitle()}
      >
        <Box className={`content-wrapper ${this.state.isShowSuccessfullyBookingEmail ? 'success' : ''}`}>
        {!this.state.isShowSuccessfullyBookingEmail ? (
          <Formik
            enableReinitialize
            initialValues={{
              email: "",
            }}
            validationSchema={addBookingSchema}
            onSubmit={(values) => {
              this.handleAddBookingDialogSave(values);
            }}
            data-test-id="Formik"
          >
            {({ errors, touched, handleSubmit, getFieldProps, values, setFieldValue, setFieldError }) => {
              return (
                <>
                  <form onSubmit={handleSubmit}>
                    <InputField
                      {...getFieldProps("email")}
                      fullWidth
                      id="email"
                      placeholder={this.transCalendar("Client email address")}
                      label={this.transCalendar("Email")}
                      type="text"
                      error={
                        this.getErrorAndHelperText(
                          "email",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).isError
                      }
                      startAdornment={<img src={emailIcon} alt="email-icon" />}
                      helperText={
                        this.getErrorAndHelperText(
                          "email",
                          errors as FormError,
                          touched as FormErrorTouched
                        ).helperText
                      }
                      data-test-id="Input-Email"
                      onChange={(e) => {
                        setFieldValue('email', e.target.value)
                        if(this.state.addBookingApiError) {
                          this.clearApiError()
                        }
                      }}
                    />
                    {!!this.state.addBookingApiError && <div className="error-msg">{this.state.addBookingApiError}</div>}
                    <Box className="add-booking-dialog-btn-container">
                      <Button className="dialog-cancel-btn" onClick={this.handleAddBookingClose}>{this.transCalendar("Cancel")}</Button>
                      <Button 
                        type="submit" 
                        className={`dialog-save-btn ${(!values.email || this.state.isLoadingAddBooking) && 'dialog-disable-btn'}`} 
                        disabled={!values.email || this.state.isLoadingAddBooking}
                        endIcon={this.state.isLoadingAddBooking ? <CircularProgress style={{ color: '#B7B7B7' }} size={18}/> : null}
                      >
                        {this.transCalendar("Send")}
                      </Button>
                    </Box>
                  </form>
                </>
              );
            }}
          </Formik>
        ) : (
          <Typography className="sent-successfully-email-info-text">
            {this.transCalendar("Booking email has been sent successfully")}
          </Typography>
        )}
        </Box>
      </CustomCalendarDialog>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Box style={{ display: "flex", backgroundColor: "#F6F4F9",width:'100%',border:'none' }}>
          <CalendarContainer>
            <Box className="calendar-content">
              <VendorCalendar 
                calendarView={this.props.calendarView}
                selectedService={this.props.selectedService} 
                calendarDate={this.props.calendarDate} 
                changeDate={this.props.handleChangeCalendarDate}
                changeView={this.props.handleChangeCalendarView}
                formattedEvents={this.state.calendarData}
                appointments={this.state.AppointmentArrayList}
                employees={this.state.EmployeesList}
                isLoading={this.state.isLoadingCalendarData}
                toogleAvailability={this.handleToogleAvailabilityModal}
                toogleBooking={this.handleToogleBooklingDetailsModal}
                fetchEmployeeEvents={this.getEmployeeCalendarData}
                data-test-id="VendorCalendarId"
              />
              {this.renderAddBookingButton()}
              {this.renderAppointments()}
              <VendorAppointmentList appointments={this.state.AppointmentArrayList} currentTab={this.state.tabValue} onSelect={this.handleSetAppointmentsData}/>
              {this.renderRequestTabPanel()}
              {this.renderAddBookingModel()}
              <AvailabilityModal 
                isOpen={this.state.isOpenAvailabilityModal} 
                onClose={this.handleToogleAvailabilityModal}
                disableOutside={true} 
                serviceType={this.props.selectedService.name as ServiceList}
                data={this.state.availablityData} 
                isLoading={this.state.isLoadingAvailabilityData}
                apiError={this.state.availabilityApiError}
                onClickOnAvailablity={this.handleToogleBooklingDetailsModal}
              />
              <BookingDetailsModal 
                isOpen={this.state.isOpenBookingDetailsModal} 
                onClose={this.handleToogleBooklingDetailsModal} 
                serviceType={this.props.selectedService.name as ServiceList}
                data={this.state.subDetailsData}
                isLoading={this.state.isLoadingSubDetails}
                apiError={this.state.subDetailsApiError}
                downloadCSV={this.donwloadCSV}
              />
            </Box>
          </CalendarContainer>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
// Customizable Area End
