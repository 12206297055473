import { Box, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import { activeWallet, completeWallet, goWallet, inactiveListWallet, purchaseWallet, reservationWallet } from '../../assets';
import moment from 'moment';

type Task = {
    id: number, title: string, points: number, completed: boolean
}

type History = {
    id: number, type: string, date: string, points: number
}

type Props = {
  points: string;
  translate: (value: string) => string;
  taskList: Task[];
  history: History[];
}

const IconType: Record<string, string> = {
    "Reservation": reservationWallet,
    "Coupon purchase": purchaseWallet
}

const MyPointPage = ({ points, translate, taskList, history }: Props) => {

    const getCardText = (task: Task) => {
        if(task.completed) {
            return `${translate("You gained")} ${task.points} ${translate("points")}`
        }

        return `+ ${task.points} ${translate('points')}`
    } 

    return (
        <Wrapper>
            <Box className='points-wrapper'><Typography className='points-number'>{`${points} ${translate('points')}`}</Typography></Box>
            <Typography className='points-tip'>{translate('tip')}</Typography>
            <Box className='task-list-wrapper'>
                {taskList.map(task => (
                    <Box className='task-card' style={{ background: task.completed ? '#F2F2F2' : '#fff'}} key={`task-${task.id}`}>
                        <img src={task.completed ? activeWallet : inactiveListWallet } className='task-image'/>
                        <Typography className={task.completed ? 'task-completed-text' : 'task-text'}>{task.title}</Typography>
                        <Typography className='task-points' style={{ color: task.completed ? "#FFCE00" : "#6F05EA"}}>{getCardText(task)}</Typography>
                        <img src={task.completed ? completeWallet : goWallet } className='task-image'/>
                    </Box>
                ))}
            </Box>
            <Box className='divider'/>
            <Typography className='history-text'>{translate('Point history')}</Typography>
            <TableContainer component={Box}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '40px'}}><Typography className="p-table-header">{translate("Type")}</Typography></TableCell>
                            <TableCell><Typography className="p-table-header">{translate("Title")}</Typography></TableCell>
                            <TableCell><Typography className="p-table-header">{translate("Date")}</Typography></TableCell>
                            <TableCell align='right'>
                                <Typography className="p-table-header" style={{ color: '#6F05EA'}}>{translate("Points")}</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                     {history.map(row => (
                        <TableRow>
                            <TableCell>
                                <img src={IconType[row.type]}/>
                            </TableCell>
                            <TableCell><Typography className='p-talbe-body'>{row.type}</Typography></TableCell>
                            <TableCell><Typography className='p-talbe-body'>{moment(row.date, 'DD/MM/YYYY').format("DD/MM/YYYY")}</Typography></TableCell>
                            <TableCell align='right'>
                                <Typography className='p-table-points' style={{ color: row.type === 'Reservation' ? '#6F05EA' : '#CC5252'}}>
                                    {`${row.type === 'Reservation' ? '+' : '-'} ${row.points} ${translate('points')}`}
                                    </Typography>
                            </TableCell>
                        </TableRow>
                     ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Wrapper>
    ) 
}

const Wrapper = styled(Box)(({ theme }) => ({
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    "& .p-table-points": {
        fontSize: '20px',
        fontWeight: 600,
        color: '#6F05EA',
        [theme.breakpoints.down(660)]: {
            fontSize: '16px',
        },
    },

    "& .p-talbe-body": {
        fontSize: '18px',
        fontWeight: 500,
        color: '#000',
        [theme.breakpoints.down(660)]: {
            fontSize: '14px',
        },
    },
    "& .p-table-header": {
        fontSize: '14px',
        fontWeight: 600,
        color: '#B7B7B7'
    },
    "& .MuiTableCell-root": {
        borderBottom: 'none'
    },
    "& .history-text": {
        fontSize: '24px',
        fontWeight: 600,
        color: '#6F05EA',
        marginBottom: '47px',
        [theme.breakpoints.down(660)]: {
            marginBottom: '17px',
        },
    },

    "& .divider": {
        width: '100%',
        borderTop: '1px solid #B7B7B7',
        marginTop: '55px',
        marginBottom: '47px',
        [theme.breakpoints.down(660)]: {
            marginTop: '25px',
            marginBottom: '17px',
        },
    },

    "& .task-image": {
        [theme.breakpoints.down(660)]: {
            width: '23px',
            maxWidth: '23px',
            height: 'auto'
        },
    },

    "& .task-points": {
        fontSize: '22px',
        fontWeight: 600,
        [theme.breakpoints.down(660)]: {
            fontSize: '16px',
        },
    },

    "& .task-text": {
        fontSize: '20px',
        fontWeight: 600,
        color: '#424242',
        flex: 1,
        [theme.breakpoints.down(660)]: {
            fontSize: '14px',
        },
    },
    "& .task-completed-text": {
        fontSize: '20px',
        fontWeight: 600,
        flex: 1,
        color: '#B7B7B7',
        [theme.breakpoints.down(660)]: {
            fontSize: '14px',
        },
        textDecoration: 'line-through'
    },
    "& .task-card": {
        padding: '30px',
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        gap: '34px',
        boxShadow: "0px 0px 7.5px 0px #0000001A",

        [theme.breakpoints.down(660)]: {
            padding: '16px',
            gap: '16px',
        },
    },
    "& .points-wrapper": {
        border: '2px solid #FFCE00',
        borderRadius: '35px',
        padding: '10px 40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    "& .points-number": {
        fontSize: '32px',
        fontWeight: 600,
        color: "#000"
    },
    "& .points-tip": {
        marginTop: '18px',
        color: "#6F05EA",
        fontSize: '20px',
        fontWeight: 600,
    },
    "& .task-list-wrapper": {
        marginTop: '36px',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        width: '100%'
    },

    [theme.breakpoints.down(660)]: {
        marginTop: '20px'
    },
}))

export default MyPointPage;