// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IFilteredService } from "../../../../components/src/interfaces.web";
import { APIModal } from "./ServiceDashboardController.web";
import i18n from "../../../../components/src/i18next/i18n";

const webConfigJSON = require("../config.js");
interface IAppoinment {
    service_date: string
    service_type: string
    customer_name: string
    customer_phone: string
    service_status: "Completed" | "Booked" | "Cancelled"
    service_charge: string,
    id: number
}
export interface IAppointmentAttr {
    id: number,
    shift: string,
    start_date: string,
    end_date: string,
    price: string,
    total_price: string,
    repeat_every: string | null,
    start_time: string | null,
    week_days: string[],
    repeat_weekly: boolean,
    status: string | null,
    created_at: string,
    sub_service: {
        id: number,
        title: string
    },
    service: {
        id: number,
        service_type: string
    },
    customer: {
        id: number,
        name: string,
        email: string,
        full_phone_number: string,
        phone_number: number
    },
    payment_status: string
}
export interface IAppointmentList {
    id: string,
    type: string,
    attributes: IAppointmentAttr

}
export interface IAppointmentResp {
    data: IAppointmentList[],
    meta: {
        message: string
    }
}

// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    appointmentProp: {
        selectedService: IFilteredService;
        startDate: string,
        endDate: string
    }
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    searchValue: string,
    appointmentList: IAppoinment[],
    filteredList: IAppoinment[],
    openBookingDetails: boolean,
    isListLoading: boolean,
    apiToken: string,
    scheduleId: string
    // Customizable Area End
}
interface SS { }

// Customizable Area Start
// Customizable Area End

export default class AppoinmentsDashboardController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    apiAppointmentListCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.AlertMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            searchValue: "",
            appointmentList: ([] as IAppoinment[]),
            filteredList: ([] as IAppoinment[]),
            openBookingDetails: false,
            isListLoading: false,
            apiToken: localStorage.getItem("login_token") || "",
            scheduleId: ""
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId === this.apiAppointmentListCallId) {
                this.handleAppointmentListResp(responseJson)
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    handleChangeSearch = (value: string) => {
        const trimmedValue = value.trimStart()
        const fieldsToFilter: Partial<keyof IAppoinment>[] = [
            "service_type",
            "customer_name",
            "customer_phone",
            "service_status",
            "service_charge",
        ];

        const filteredArr = (this.state.appointmentList).filter((item) =>
            fieldsToFilter.some((field) =>
                item[field as keyof IAppoinment]?.toString().toLowerCase().includes(trimmedValue.toLowerCase())
            )
        );

        this.setState({
            searchValue: trimmedValue,
            filteredList: filteredArr
        })
    }
    handleOpenBookingDetails = (schedule_id: number) => {
        this.setState({
            openBookingDetails: true,
            scheduleId: schedule_id.toString()
        })
    }
    handleCloseBookingDetails = () => {
        this.setState({
            openBookingDetails: false,
            scheduleId: ""
        })
    }

    apiCall = async (data: APIModal) => {
        const { contentType, method, endPoint, body, token, formData } = data;
        let header;
        header = {
            "Content-Type": contentType,
        };
        if (token) {
            header = {
                "Content-Type": contentType,
                token: token,
            };
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );

        formData &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                formData
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        if (prevProps.appointmentProp.startDate !== this.props.appointmentProp.startDate || prevProps.appointmentProp.endDate !== this.props.appointmentProp.endDate) {
            this.getAppointmentList();
        }
    }

    getAppointmentList = async () => {
        const { isListLoading, apiToken } = this.state;
        const { startDate, endDate } = this.props.appointmentProp;
        this.setState({ isListLoading: !isListLoading });
        this.apiAppointmentListCallId = await this.apiCall({
            method: webConfigJSON.validationApiMethodType,
            endPoint: `${webConfigJSON.ENDPOINTS.GET_APPOINTMENT_LIST}service_id=${this.props.appointmentProp.selectedService.service_id}&start_date=${startDate}&end_date=${endDate}`,
            token: apiToken,
        });
    };

    handleAppointmentListResp = (responseJson: IAppointmentResp) => {
        this.setState({ isListLoading: !this.state.isListLoading });
        const convertList: IAppoinment[] = responseJson.data.map(({ attributes }) => ({
            service_date: attributes.start_date,
            service_type: attributes.service.service_type,
            customer_name: attributes.customer.name,
            customer_phone: attributes.customer.phone_number.toString(),
            service_status: attributes.payment_status as "Completed" | "Booked" | "Cancelled",
            service_charge: attributes.total_price,
            id: attributes.id
        }));
        this.setState({ appointmentList: convertList, filteredList: convertList });
    }
   
    transAppointmentDash = (appDashKey: string) => {
        return i18n.t(appDashKey, { ns: "serviceDashboard" });
    }
    // Customizable Area End
}

