import { Box, styled, Tab, Tabs, Typography } from '@material-ui/core';
import React from 'react';
import VerticalTabs from "../../../../components/src/VerticalTabs.web";
import MyAwardsPage from './Wallet/MyAwardsPage';
import MyPointsPage from './Wallet/MyPointPage';
import TradePointsPage from './Wallet/TradePointsPage';

export const mockData = {
    tradeItems: [
        { 
            id: 1, 
            imgSrc: 'https://lovepetws2023-436904-ruby.b436904.dev.eastus.az.svc.builder.cafe/assets/no_image-9f3a00e41a77ba96e4bb746f3069e766744fefd41b99ae220669f56cfd3af55f.png',
            title: '10% off discount coupon',
            desc: 'On hotels and daycare',
            amount: 100,
            disabled: false
        },
        { 
            id: 2, 
            imgSrc: 'https://lovepetws2023-436904-ruby.b436904.dev.eastus.az.svc.builder.cafe/assets/no_image-9f3a00e41a77ba96e4bb746f3069e766744fefd41b99ae220669f56cfd3af55f.png',
            title: 'Gift Voucher',
            desc: 'On grooming',
            amount: 45,
            disabled: true
        }
    ],
    points: "125",
    taskList: [
        { id: 1, title: 'Fill Goldie general information', points: 1, completed: true },
        { id: 2, title: 'Fill medical information for Goldie', points: 1, completed: false }
    ],
    history: [
        {id: 1, type: 'Reservation', date: "20/12/2023", points: 59 },
        {id: 2, type: 'Coupon purchase', date: "18/11/2023", points: 10 },
        {id: 3, type: 'Reservation', date: "12/08/2023", points: 25 },
        {id: 4, type: 'Coupon purchase', date: "18/11/2023", points: 5 },
    ],
    currentAwards: [
        { 
            id: 1, 
            imgSrc: 'https://lovepetws2023-436904-ruby.b436904.dev.eastus.az.svc.builder.cafe/assets/no_image-9f3a00e41a77ba96e4bb746f3069e766744fefd41b99ae220669f56cfd3af55f.png',
            title: '10% discount coupon',
            desc: 'On hotels and daycare',
            code: 'RH6OV7',
            validTo: new Date()
         },
         { 
            id: 2, 
            imgSrc: 'https://lovepetws2023-436904-ruby.b436904.dev.eastus.az.svc.builder.cafe/assets/no_image-9f3a00e41a77ba96e4bb746f3069e766744fefd41b99ae220669f56cfd3af55f.png',
            title: '10% discount coupon',
            desc: 'On hotels and daycare',
            code: 'RH6OV7',
            validTo: new Date()
         },
         { 
            id: 3, 
            imgSrc: 'https://lovepetws2023-436904-ruby.b436904.dev.eastus.az.svc.builder.cafe/assets/no_image-9f3a00e41a77ba96e4bb746f3069e766744fefd41b99ae220669f56cfd3af55f.png',
            title: '10% discount coupon',
            desc: 'On hotels and daycare',
            code: 'RH6OV7',
            validTo: new Date()
         },
         { 
            id: 4, 
            imgSrc: 'https://lovepetws2023-436904-ruby.b436904.dev.eastus.az.svc.builder.cafe/assets/no_image-9f3a00e41a77ba96e4bb746f3069e766744fefd41b99ae220669f56cfd3af55f.png',
            title: '10% discount coupon',
            desc: 'On hotels and daycare',
            code: 'RH6OV7',
            validTo: new Date()
         },
    ],
    pastAwards: []
}

type Props = {
    currentTabValue: number;
    setCurrentTab: (value: number) => void;
    translate: (value: string) => string;
}

const CustomerWallet = ({ currentTabValue, translate, setCurrentTab }: Props) => {

    const renderSettingsTabContent = () => {
        switch (currentTabValue) {
            case 0:
                return <MyPointsPage points={mockData.points} translate={translate} taskList={mockData.taskList} history={mockData.history}/>;
            case 1:
                return <MyAwardsPage currentAwards={mockData.currentAwards} pastAwards={mockData.pastAwards} translate={translate}/>;
            case 2:
                return <TradePointsPage tradeItems={mockData.tradeItems} translate={translate} myPoints={mockData.points}/>;
            default:
                break;
        }
    }

    return (
        <ResponsiveWrapper>
            <Typography className='header'>{translate('Wallet')}</Typography>
            <Box className="mobile-settings-tabs">
                <VerticalTabs
                    value={currentTabValue}
                    data-test-id="settings-tabs-menu"
                    onChange={(_, idx) => setCurrentTab(idx)}
                >
                    <Tab label={translate('My points')} className="vertical-tab" />
                    <Tab label={translate('My awards')} className="vertical-tab" />
                    <Tab label={translate('Trade points')} className="vertical-tab" />
                </VerticalTabs>
            </Box>
            <Box className="desktop-settings-tabs">
                <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    value={currentTabValue}
                    data-test-id="settings-tabs"
                    onChange={(_, idx) => setCurrentTab(idx)}
                    TabIndicatorProps={{
                    style: {
                        backgroundColor: '#6F05EA',
                        height: "3px"
                    },
                    }}
                >
                    <Tab style={{maxWidth: "33%", fontSize: "20px", fontWeight: 700, borderBottom: "1px solid #B7B7B7"}} label={translate('My points')} className="custom-tab" />
                    <Tab style={{maxWidth: "33%", fontSize: "20px", fontWeight: 700, borderBottom: "1px solid #B7B7B7"}} label={translate('My awards')} className="custom-tab" />
                    <Tab style={{maxWidth: "33%", fontSize: "20px", fontWeight: 700, borderBottom: "1px solid #B7B7B7"}} label={translate('Trade points')} className="custom-tab" />
                </Tabs>
            </Box>
                {renderSettingsTabContent()}
        </ResponsiveWrapper>
    )
}


const ResponsiveWrapper = styled(Box)(({ theme }) => ({
    width: '100%', 
    textAlign: 'left',

    "& .mobile-settings-tabs": {
        [theme.breakpoints.up(660)]: {
        display: 'none'
        },
    },
    "& .desktop-settings-tabs": {
        [theme.breakpoints.down(660)]: {
            display: 'none'
        },
    },
    "& .header": {
        fontWeight: 600,
        fontSize: '40px',
        color: '#000000',
        marginBottom: '10px'
    }

}))

export default CustomerWallet;